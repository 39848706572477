import React, { Component } from 'react';
import gql from 'graphql-tag';
import {
  Accordion,
} from 'react-accessible-accordion';
import DrugsList from './druglist.jsx';

class DrugSearch extends Component {
  state = {
    searchQuery: '',
    limit: 10,
    total: 0,
    cursor: '',
    catType: null,
    selectedIndex: null,
  }

  handleSubmit = event => event.preventDefault();

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleButtonClick = (event) => {
    this.setState({
      catType: event.currentTarget.dataset.id,
    });
  }

  queryMe = data => {
    this.setState({
      total: data,
    })
  };

  render() {
    const { state, handleSubmit, handleInputChange, handleButtonClick, queryMe } = this;
    const { searchQuery, limit, total, cursor, catType, selectedIndex } = state;
    const filterList = [
      {
        id: null,
        title: 'View All',
        subtitle: '',
        slug: 'all'
      },
      {
        id: 37,
        title: 'Permitted',
        subtitle: 'No Warnings',
        slug: 'permitted'
      },
      {
        id: 38,
        title: 'Prohibited',
        subtitle: 'At All Times',
        slug: 'prohibited-at-all-times'
      },
      {
        id: 39,
        title: 'Prohibited',
        subtitle: 'In-Competition',
        slug: 'prohibited-in-competition'
      },
      {
        id: 40,
        title: 'Prohibited',
        subtitle: 'In Certain Sports',
        slug: 'prohibited-in-certain-sports'
      },
      {
        id: 41,
        title: 'Prohibited',
        subtitle: 'Male Athletes',
        slug: 'prohibited-male-athletes',
      },
    ];
    
    const listFilter = filterList.map((button) => (
        <div key={button.slug} className={(catType == null ? 'all-selected' : catType == button.id ? 'selected' : 'not-selected') +' '+ (button.slug)} data-id={button.id}  onClick={handleButtonClick.bind(this)}>
            <h3>{button.title}</h3>
            {button.subtitle && <span>{button.subtitle}</span>}
        </div>
      )
    );
    return (
      <div className="posts-search">
        <form className="posts-search__form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="posts-search__search-field"
            name="searchQuery"
            value={searchQuery}
            onChange={handleInputChange}
            placeholder="Type medication name here…"
          />
          <div className="filter-list">
          {listFilter}
          </div>
        </form>
        <div className="total">
          ({total} found)
        </div>
        <div className="results">
        <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            <DrugsList searchQuery={searchQuery} limit={limit} queryMe={queryMe} cursor={cursor}  catType={catType}/>
        </Accordion>
          </div>
      </div>
    );
  }
}




export default DrugSearch;
