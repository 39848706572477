import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import Moment from 'moment';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';
// Helper function for formatting dates.
//const formatDate = date => new Date( date ).toDateString();

class DrugCard extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleClick = () => {
    this.props.onLoadMore();
  };

  render() {
    const drugs = this.props.drugs || [];
    return (
      <div>
        {drugs.edges.map(({ node }, id) => (
          <Collapsible
            key={id}
            trigger={node.title}
            openedClassName={
              node.drugCategories.edges[0].node.slug + ' is-open'
            }
            className={node.drugCategories.edges[0].node.slug}
          >
            <div className="drug-panel">
              <p>
                <strong>Category: </strong>
                {node.drugCategories.edges[0].node.name}
              </p>
              <p>
                <strong>Last Updated: </strong>
                {Moment(node.modified).format('ddd, D MMM YYYY')}
                {}
              </p>
              <div>
                <strong>Information</strong>
                <div dangerouslySetInnerHTML={{ __html: node.content }} />
              </div>
              <p>
                <strong>Find out more and </strong>{' '}
                <a
                  target="_blank"
                  href={
                    'https://en.wikipedia.org/w/index.php?search=' + node.title
                  }
                >
                  Search on wikipedia
                </a>
              </p>
            </div>
          </Collapsible>
        ))}
        {this.props.loading && (
          <ClipLoader size={150} color={'#123abc'} loading={true} />
        )}

        {this.props.hasMore && (
          <div className="loading-btn">
            <button className="btn btn-red" onClick={this.handleClick}>
              Load More
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default DrugCard;
