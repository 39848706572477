import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import DrugCard from './drugcard.jsx';
import { css } from '@emotion/core';
import ClipLoader from 'react-spinners/ClipLoader';

// This is the query that Apollo Client will send to the WP site.
const POSTS_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY(
    $searchQuery: String!
    $limit: Int
    $cursor: String!
  ) {
    drugs(
      where: { search: $searchQuery, orderby: { field: TITLE, order: ASC } }
      first: $limit
      before: $cursor
    ) {
      edges {
        node {
          id
          title
          content
          modified
          drugCategories {
            edges {
              node {
                name
                slug
              }
            }
          }
        }
      }
      pageInfo {
        total
        hasNextPage
        endCursor
      }
    }
  }
`;

const CAT_SEARCH_QUERY = gql`
  query POSTS_SEARCH_QUERY(
    $searchQuery: String!
    $limit: Int
    $cursor: String!
    $catType: ID!
  ) {
    drugCategory(idType: DATABASE_ID, id: $catType) {
      drugs(
        where: { search: $searchQuery, orderby: { field: TITLE, order: ASC } }
        first: $limit
        before: $cursor
      ) {
        edges {
          node {
            id
            title
            content
            modified
            drugCategories {
              edges {
                node {
                  name
                  slug
                }
              }
            }
          }
        }
        pageInfo {
          total
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const DrugsList = ({ searchQuery, limit, queryMe, cursor, catType }) => (
  <Query
    query={catType ? CAT_SEARCH_QUERY : POSTS_SEARCH_QUERY}
    variables={{ searchQuery, limit, cursor, catType }}
    onCompleted={(data) =>
      queryMe(
        catType
          ? data.drugCategory.drugs.pageInfo.total
          : data.drugs.pageInfo.total
      )
    }
  >
    {({ loading, error, data, fetchMore }) => {
      if (loading)
        return (
          <div className="loading-container">
            <ClipLoader size={50} color={'#1A82C0'} loading={true} />
          </div>
        );
      if (error) return <p>Error :(</p>;
      var drugs = [];
      if (catType) {
        drugs = data.drugCategory.drugs;
      } else {
        drugs = data.drugs;
      }
      if (drugs.edges.length == 0)
        return (
          <div className="no-results">
            No results found. Please adjust search.
          </div>
        );
      return (
        <DrugCard
          loading={loading}
          drugs={drugs}
          hasMore={drugs.pageInfo.hasNextPage}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: drugs.pageInfo.endCursor,
              },
              updateQuery: (prevResult, { fetchMoreResult }) => {
                const newEdges = catType
                  ? fetchMoreResult.drugCategory.drugs.edges
                  : fetchMoreResult.drugs.edges;
                const pageInfo = catType
                  ? fetchMoreResult.drugCategory.drugs.pageInfo
                  : fetchMoreResult.drugs.pageInfo;
                if (catType) {
                  return newEdges.length
                    ? {
                        drugCategory: {
                          __typename: prevResult.drugCategory.__typename,
                          drugs: {
                            __typename:
                              prevResult.drugCategory.drugs.__typename,
                            edges: [
                              ...prevResult.drugCategory.drugs.edges,
                              ...newEdges,
                            ],
                            pageInfo,
                          },
                        },
                      }
                    : prevResult;
                } else {
                  return newEdges.length
                    ? {
                        drugs: {
                          __typename: prevResult.drugs.__typename,
                          edges: [...prevResult.drugs.edges, ...newEdges],
                          pageInfo,
                        },
                      }
                    : prevResult;
                }
              },
            })
          }
        />
      );
    }}
  </Query>
);

export default DrugsList;
