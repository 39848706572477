import React, { Component } from "react"
import ReactDOM from "react-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider, Query } from "react-apollo";
import gql from 'graphql-tag';


import DrugSearch from './react/drugs/drugsearch.jsx';

const client = new ApolloClient({
  // Change this to the URL of your WordPress site.
  uri: "/graphql/"
});

const App = () => (
  <ApolloProvider client={client}>
    <div className="app">
      <DrugSearch  />
    </div>
  </ApolloProvider>
);

  if ( document.getElementById("drug-app") ) {
    ReactDOM.render(<App />, document.getElementById("drug-app"));
  }
  

